@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato|Lora);
body {
  margin: 0;
  font-family: 'Poppins', arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

/* add by deepak */

.slick-center {
  border-radius: 20px;
}

/* carosal Image  */
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #ffee00 !important;
  width: 47px;
  height: 104px;
  top: 0px;
  z-index: 999;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}

.carousel .slick-prev {
  left: 0;
  z-index: 2;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 30% !important;
  display: block;
  width: 20px;
  height: 80px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: none;
}

/* .carousel .slick-prev {
  right: 0;
  z-index: 1000;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
} */

.slick-prev::before,
.slick-next::before {
  display: none;
}



/* end the code  */





/* Header Component */
.header-container {
  background-color: white;
  padding: 40px 0;
}

.navigation-wrapper {
  display: flex;
  justify-content: space-between;
}

.navigation-wrapper-menu {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

/* Welcome Component */
.welcome-container,
.newsletter-main-container {
  padding: 40px 0;
  background: #060c27;
}

.newsletter-sub-container {
  border-bottom: 1px solid white;
  padding-bottom: 30px;
}

.footer-container {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: flex-end;
  display: flex;
}

.footer-logo-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: self-end;
}

.footer-logo-section>a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
}

.footer-logo-section>a:hover {
  color: white;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
}

.footer-icons {
  display: flex;
  justify-content: flex-end;
}

.footer-icons>a {
  cursor: pointer;
  text-decoration: none;
}

.footer-icons>a>img {
  width: 40px;
  height: 40px;
  margin-left: 30px;
}

.artgallery-container,
.blog-container,
.upcoming-container {
  background-color: #fff;
  margin: 60px auto;
}

.upcoming-content-wrapper,
.submitnft-content-wrapper,
.community-content-wrapper {
  margin: 57px 0 77px;
}

.upcoming-wrapper {
  margin-top: 30px;
  background-color: #060c27;
}

.community-wrapper {
  margin-top: 30px;
  background-color: #060c27;
}

.community-img-container {
  padding: 100px 0 50px;
}

.upcoming-img,
.sumbitnft-img,
.community-img {
  width: 100%;
}

.upcoming-content-container,
.submitnft-content-container,
.community-content-container {
  margin: auto 0;
}

.welcome-heading,
.newsletter-heading {
  color: white;
  font-size: 4.5rem;
  font-weight: 600;
  font-family: 'Poppins';
}

.welcome-subheading {
  margin-top: 5rem;
  color: white;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.2rem;
}

.art-container,
.blogcard-container {
  margin: 0;
  border-bottom: 2px solid #cbcbcb;
}

.art-card-container,
.blog-card-container,
.faq-card-container,
.community-container {
  width: 100%;
  padding: 60px 8px 60px;
}

.artgallery-heading,
.blogcard-heading {
  color: #060c27;
  font-size: 4.5rem;
  font-weight: 400;
  font-family: 'Poppins';
}


.benifits-heading {
  color: #ffffff;
  font-size: 4.5rem;
  font-weight: 400;
  font-family: 'Poppins';
}

.textbenifits {
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 500;
  font-family: 'Poppins';
  text-align: start;
  line-height: 1.5;
}

.community-heading-wrapper {
  padding: 0;
  width: 100%;
}

.community-partner-wrapper {
  margin: 70px 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.community-logo-container {
  border: 0;
}

.community-partner-wrapper>.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .community-partner-wrapper>.row {
    width: 75%;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .community-partner-wrapper>.row {
    justify-content: space-evenly;
  }
}

.community-partner-wrapper>.row>.card {
  width: auto;
  border: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
}

.artgallery-heading-wrapper,
.blogcard-heading-wrapper {
  padding: 0;
}

.artgallery-subheading,
.blogcard-subheading {
  margin-left: 20px;
}

.art-wrapper,
.blog-wrapper,
.faq-wrapper {
  width: 100%;
  margin: 0;
}

.col {
  margin-bottom: 30px;
}

.card-title {
  font-weight: 600;
  color: black;
  font-family: 'Poppins';
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 30px;
  padding-top: 12.5px;
  margin-left: 12.5px;
  margin-right: 12.5px;
}

.card-text {
  margin-top: 25px;
  margin-bottom: 25px !important;
  margin-left: 12.5px;
  margin-right: 12.5px;
}

/* .card-img-top {
  min-height: 370px;
} */

.card-text>span>img {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 2px rgb(0 0 0 / 10%);
}

.card {
  box-shadow: -1px -2px 45px 10px rgba(0, 0, 0, 0.1);
}

.submit-work-wrapper {
  flex-direction: row;
  justify-content: end;
}

.header-submit-work {
  font-family: 'poppins', sans-serif;
  color: white;
  padding: 10px 10px;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.5s linear;
  border: 1px solid #ffffff;
  margin-top: 0;
  align-items: center;
  font-size: 1.5rem;
}

.submit-work {
  font-family: 'poppins', sans-serif;
  color: black;
  /* background-color: transparent; */
  padding: 10px 20px;
  letter-spacing: 1px;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.5s linear;
  border: 1px solid #ffffff;
  margin-top: 2rem;
  align-items: center;
  font-size: 1.5rem;
}

.artgallery-knowmore {
  border: 1px solid #07113d;
  color: black;
  border-radius: 15px;
  padding: 7px 15px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.blogcard-knowmore {
  border: 1px solid #07113d;
  color: black;
  border-radius: 15px;
  padding: 5px 10px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
}

.artgallery-knowmore:hover,
.blogcard-knowmore:hover {
  text-decoration: none;
  color: black;
}

.accordion-item {
  border: 0.5px solid #cdcdcd;
  box-sizing: border-box;
  box-shadow: 13px 8px 30px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;

  margin-bottom: 50px;
  font-family: 'Poppins';
}

.accordion-header {
  padding: 10px !important;
  margin: 0;
  border-radius: 6px;
}

.accordion-button {
  font-size: 18px;
  text-decoration: none;
  outline: none;
}

.accordion-button:focus {
  border: 0;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: #fff;
}

.accordion-body {
  color: black;
  padding: 22.5px !important;
}

.newsletter-link-container {
  display: flex;
  justify-content: flex-end;
}

.header-redirections {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: auto 0;
  font-size: 14px;
  color: black;
  font-weight: 500;
  justify-content: flex-start;
}

.header-search-bar-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-search-bar-wrapper>.search-input {
  width: 100%;
  outline: 0;
  border-radius: 5px;
  border: 1px solid #cdcdcd;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: -1px 0px 9px 2px rgba(219, 219, 219, 1);
}

.header-redirections>a {
  text-decoration: none;
  color: #07113d;
  margin: 0 10px;
}

.header-redirections>a:hover {
  text-decoration: none;
  color: #07113d;
  cursor: pointer;
}

.newsletter-redirections {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 0;
  font-size: 24px;
  color: white;
  font-weight: 600;
}

.newsletter-redirections>a {
  text-decoration: none;
  color: white;
}

.newsletter-redirections>a:hover {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.know-more-anchor {
  font-family: 'poppins', sans-serif;
  color: #fff;
  background-color: transparent;
  padding: 10px 20px;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.5s linear;
  border: 1px solid #ffffff;
  margin-top: 5rem;
  font-size: 1.5rem;
}

/* Burger Menu */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.menu-container {
  padding-left: 50px;
}

.menu-container>.burger-icon {
  width: 40px;
  margin: auto 0;
  cursor: pointer;
}

/* Modal Style */
.modal-open .modal {
  background-color: rgb(61 61 61/70%);
}





/* slider  */

/* carosal Image  */
.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: #ffee00;
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}

.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.carousel .slick-next {
  right: 0;
  z-index: 1;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.custom-indicator {
  bottom: -65px;
}

.custom-indicator li {
  width: 50px;
  height: 50px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.custom-indicator li.slick-active {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

.multi__image:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 500ms;
}

.slick-slider {
  width: 100%;
}

    
  #orangeButton {
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fea054,
              0 0 .2rem rgb(70, 70, 70),
              0 0 2rem #fea054,
              0 0 0.8rem #fea054,
              0 0 2.8rem #fea054,
              inset 0 0 1.3rem #fea054; 
  }
  
  .addOrangeNeon:hover{
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fea054,
              0 0 .2rem rgb(70, 70, 70),
              0 0 2rem #fea054,
              0 0 0.8rem #fea054,
              0 0 2.8rem #fea054,
              inset 0 0 1.3rem #fea054; 
  }

  @-webkit-keyframes pulsate {
      
    100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #bc13fe,
        0 0 80px #bc13fe,
        0 0 90px #bc13fe,
        0 0 100px #bc13fe,
        0 0 150px #bc13fe;
    
    }
    
    0% {
      text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #bc13fe,
      0 0 45px #bc13fe,
      0 0 55px #bc13fe,
      0 0 70px #bc13fe,
      0 0 80px #bc13fe;
  }
}

  @keyframes pulsate {
      
    100% {
  
        text-shadow:
        0 0 4px #fff,
        0 0 11px #fff,
        0 0 19px #fff,
        0 0 40px #bc13fe,
        0 0 80px #bc13fe,
        0 0 90px #bc13fe,
        0 0 100px #bc13fe,
        0 0 150px #bc13fe;
    
    }
    
    0% {
      text-shadow:
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px #fff,
      0 0 10px #bc13fe,
      0 0 45px #bc13fe,
      0 0 55px #bc13fe,
      0 0 70px #bc13fe,
      0 0 80px #bc13fe;
  }
}
html {
  display: grid;
  min-height: 100%;
}



h3 {
  font-size: 0.8em;
  letter-spacing: 1px;
  font-weight: 100;
}

.RoadMap_container-fluild__10eHS{
  background:#252C32;
  width: 100%; 
  height: 100vh; 
}
.RoadMap_container__2s2oO {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 500px;
  height: 700px;
}

.RoadMap_bar__1ppD2 {
  position: absolute;
  height: 85%;
  width: 2px;
  left: 7%;
  top: 7.5%;
  background: #F4E8DA;
}

.RoadMap_circle__3J3R0 {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  left: -5px;
  border: 1px solid #F4E8DA;
  box-shadow: 0px 0px 7px rgba(#F4E8DA, 100%);
}

.RoadMap_top__4FJyO {
  background: #5B4644;
  top: -1%;
}
.RoadMap_middle__1f2sf {
  background: #87624F;
  top: 38%;
}
.RoadMap_bottom__1LdgT {
  background: #DAB7A3;
  bottom: -1%;
}

.RoadMap_bold__1GYef {
  color: #87624F;
  position: absolute;
  top: -7%;
  left: -17px;
}

.RoadMap_creamy__3cXUs {
  color: #DAB7A3;
  position: absolute;
  bottom: -7%;
  left: -27px;
}

.RoadMap_info__32s1P {
  width: 55%;
  height: 100%;
  position: absolute;
  left: 43%;
}


.RoadMap_coffee-cups__ZqLd6 {
  width: 40%;
  height: 100%;
  position: absolute;
  left: 10%;
  top: 3%;
}

.RoadMap_handle__1K4Yp {
  position: absolute;
  height: 20px;
  width: 15px;
  background: transparent;
  border-radius: 50% 30%;
  left: 95%;
  top: 10%;
  z-index: -1;
  border: 2px solid #F4E8DA;
}

.RoadMap_cup__1V9KA {
  position: absolute;
  width: 70px;
  height: 55px;
  background: #252C32;
  border: 2px solid #F4E8DA;
  border-radius: 0 0 5rem 5rem;
  left: 20%;
}

.RoadMap_cup-small__1v7r5 {
  position: absolute;
  width: 55px;
  height: 40px;
  background: #252C32;
  border: 2px solid #F4E8DA;
  border-radius: 0 0 5rem 5rem;
  left: 23%;
}

.RoadMap_cup-long__3n2s4 {
  position: absolute;
  width: 60px;
  height: 70px;
  background: #252C32;
  border: 2px solid #F4E8DA;
  border-radius: 10% 10% 50% 50%;
  left: 22%;
}

.RoadMap_doppio__2AE8B {
  top: 5%;
}

.RoadMap_americano__1yLWK {
  top: 20%;
     background: linear-gradient(to bottom, #252C32 20%, #F4E8DA 20%, #F4E8DA 40%, #87624F 40%);
}

.RoadMap_macchiato__UP-8J {
  top: 35%;
   background: linear-gradient(to bottom, #252C32 20%, #F4E8DA 20%, #F4E8DA 40%, #87624F 40%);
}

.RoadMap_flatwhite__3hnyZ {
  top: 50%;
  background: linear-gradient(to bottom, #252C32 10%, #F4E8DA 10%, #DAB7A3 60%, #87624F 65%, #5B4644 90%);
}

.RoadMap_cappuccino__21p77 {
  top: 64%;
  background: linear-gradient(to bottom, #F4E8DA 50%, #DAB7A3 50%, #DAB7A3 55%, #87624F 60%);
}

.RoadMap_latte__S2M-X {
  top: 81%;
  background: linear-gradient(to bottom, #252C32 5%, #F4E8DA 5%, #F4E8DA 30%, #DAB7A3 30%, #DAB7A3 70%, #87624F 70%);
}

h1 {
  color: #F4E8DA;
  font-size: 1em;
  letter-spacing: 3px;
}

p {
  color: #F4E8DA;
  text-transform: none;
  font-size: 0.7em;
  letter-spacing: 0.5px;
  margin-top: -5px;
}

.RoadMap_first__SBXld, .RoadMap_second__1YboE, .RoadMap_third__3SYbM, .RoadMap_fourth__3_SFb, .RoadMap_fifth__2GwqF, .RoadMap_sixth__1SlS2 {
  position: absolute;
}

.RoadMap_first__SBXld {
  top: 7%;
}

.RoadMap_second__1YboE {
  top: 21%;
}

.RoadMap_third__3SYbM {
  top: 36%;
}

.RoadMap_fourth__3_SFb {
  top: 52%;
}

.RoadMap_fifth__2GwqF {
  top: 65%;
}

.RoadMap_sixth__1SlS2 {
  top: 83%;
}


.RoadMap_small__25rss {
  margin-left: 20px;
  margin-top: -1px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.RoadMap_key__1F6Nh {
  position: relative;
  margin: auto;
  width: 500px;
  height: 200px;
}

.RoadMap_espresso__fSB7Y {
  background: #5B4644;
  position: absolute;
  left: 17%;
}

.RoadMap_milk__e7tCS {
  background: #DAB7A3;
  position: absolute;
  left: 40%;
}

.RoadMap_foam__28-7z {
  background: #F4E8DA;
  left: 83%;
}

/* footer {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 1em;
  text-transform: uppercase;
  padding: 10px;
  color: #EA7996;
  p {
    text-transform: uppercase;
    color: #DAB7A3;
    letter-spacing: 3px;
  }
  a {
  }
} */

